<template>
  <div id="onepager" class="container">
    <nav class="level">
      <div class=level-left>
        <div class="level-item">
          <img src="../assets/logo.svg" id="logo"/>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Berkecz Péter József</strong>
                  alapító<br>
                  <small>programtervező-informatikus hallgató</small>
                </p>
              </div>
            </div>
            <figure class="media-right">
              <p class="image is-64x64">
                <img src="../assets/me.jpg" />
              </p>
            </figure>
          </article>
        </div>
      </div>
    </nav>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <p class="title">Nos?</p>
          <div class="content">
            <p>
              "Enni lelkesen vagy mohón", ez a hitvallásunk. Célunk, hogy
              mindenki megszeresse a főzést és az egészséges táplálkozást.
            </p>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box notification is-info">
          <p class="title">Problémák</p>
          <div class="content">
            <ul>
              <li>Az éttermek drágák</li>
              <li>Vidéken a prémium éttermek száma korlátozott</li>
              <li>
                Nincs/jelentősen csökkentett az országos
                élelmiszer-házhozszállítás
              </li>
              <li>
                Az interneten fellelhető receptek sokszor helytelenül vannak
                megfogalmazva, esetleg olyan alapanyagokat tartalmaznak amelyek
                nem elérhetőek a felhasználó számára
              </li>
            </ul>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box notification is-primary">
          <p class="title">Megoldás</p>
          <div class="content">
            <p>
              Egy doboz, amelyben válogatott recepteket és az ahhoz szükséges
              hozzávalókat találja a vásárló. Így megspórolható a bevásárlás és
              az idegeskedés a pontos mennyiségek
            </p>
            <p>
              Hetente kap három fogást, melyek két-két főre elég adagot
              tartalmaznak.
            </p>
            <p>
              Minden héten új receptek, melyekkel a vevő új ízeket fedezhet fel.
            </p>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box notification">
          <p class="title">Versenyképesség</p>
          <div class="content">
            <p>
              Futárszolgálattal hűtős, újrahasznosítható dobozokban küldjük a csomagokat, így az egész ország területe elérhető.
            </p>
            <p>
              Jelenleg más "meal-kit" szolgálató nincs itthon.
            </p>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-4">
        <article class="tile is-child box notification">
          <p class="title">Üzleti modell</p>
          <div class="content">
            <h3>Vásárlóknak</h3>
            <p>Heti vagy havi előfizetéses modell</p>
            <h3>Márkáknak</h3>
            <p>Tartalmazza a csomag a terméküket; támogatott recept</p>
            <h3>Brandeknek</h3>
            <p>Egyéni "menü" csomagok, pl: séfek által összerakott receptek</p>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-4">
        <article class="tile is-child box notification">
          <p class="title">Célzott piac</p>
          <div class="content">
            <ol>
              <li>Hallgatók (18-26), akik megszeretnének tanulni főzni</li>
              <li>Dolgozó párok (26-45), akik a mindennapi főzés terhét csökkenteni szeretnék</li>
              <li>Olyan cégek, akik szeretnék hogy a termékük szerepeljen a csomagban</li>
              <li>Influenszerek, akik szeretnének saját csomagot létrehozni a követőiknek</li>
            </ol>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-5">
        <article class="tile is-child box notification">
          <p class="title">Mérföldkövek</p>
          <div class="content">
            <p>📦 Első előfizetett vásárló</p>
            <p>💯 100 feladott csomag hetente</p>
            <p>📈 Kinőni az első helyet, új csarnokot építeni</p>
            <p>🏪 Bővíteni a termékkört, online kisbolt</p>
            <p>👥 150 munkatárs</p>
            <p>🚚 Saját logisztikai cég</p>
            <p>🌍 Külföldi terjeszkedés</p>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-7">
        <article class="tile is-child box notification">
          <p class="title">Versenytársak, elemzések</p>
          <figure class="image is-16by8">
            <img src="../assets/map.png" id="map"/>
          </figure>
          <div class="content">
            <p>Jelenleg nagyvárosokban az Auchan, Tesco, Kifli.hu, Netpincér Market. Kisebb városokban a helyi cégek. A felsorolt cégek csupán városhatáron belül szállítanak.</p>
            <p>A pandémia rámutatott, hogy az internetes vásárlásban az emberek egyre jobban megbíznak.</p>
            <p><i>Forrás: Google Trends, 2020. 12. 22, elmúlt 1 év, "élelmiszer-házhozszállítás"</i></p>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.box {
  padding: 0.8em;
}

.title {
  font-size: 1.8em;
}

#logo {
  height: 82px;
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
}

#map {
  width: 50%;
  float: left;
  margin-right: 1em;
}

nav.level {
  margin-right: 1rem;
  margin-left: 1rem;
}

#onepager {
  text-align: left;
}

div.content > h3 {
  font-size: 1.3em;
  /*color: #fff;*/
  margin-top: 0.2rem !important;
}

/* @media screen {
  #onepager {
    padding-left: 20rem;
    padding-right: 20rem;
  }
} */
@media print {
  #onepager {
    padding: 1rem;
  }
}
</style>